// src/components/Loading.tsx
import React from 'react';
import { ClipLoader } from 'react-spinners';
import './Loading.css';

const Loading: React.FC = () => {
  return (
    <div className="loading-container">
      <ClipLoader size={100} />
    </div>
  );
};

export default Loading;
