import React, { useEffect } from 'react';
import AOS from 'aos';

// routes
import Routes from './routes/Routes';
import CookieConsentBanner from './CookieConsentBanner'; // Adjust the import path as necessary

// Themes
import './assets/scss/theme.scss';

const App: React.FC = () => {
    useEffect(() => {
        AOS.init();
    }, []);

    return (
        <>
            {/* <CookieConsentBanner /> */}
            <Routes />
        </>
    );
};

export default App;
