import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import Loading from '../components/Loading'; // Import the Loading component

// components
import PrivateRoute from './PrivateRoute';

// lazy load all the views

const Home = React.lazy(() => import('../Home'));
const AboutUs = React.lazy(() => import('../Aboutus')); // Update this line
const Contact = React.lazy(() => import('../Contact'));
const NotFound = React.lazy(() => import('../components/404')); // Add this line

const PrivacyPolicy = React.lazy(() => import('../PrivacyPolicy'));
const TermsAndConditions = React.lazy(() => import('../TermsAndConditions'));
const CookiePolicy = React.lazy(() => import('../CookiePolicy'));

type LoadComponentProps = {
    component: React.LazyExoticComponent<React.FC<{}>>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={<Loading />}>
        <Component />
    </Suspense>
);

const AllRoutes: React.FC = () => {
    return useRoutes([
        {
            path: '/',
            element: <LoadComponent component={Home} />,
        },
        {
            path: 'about-us', // Update this line
            element: <LoadComponent component={AboutUs} />, // Update this line
        },
        {
            path: 'contact',
            element: <LoadComponent component={Contact} />,
        },
        {
            path: 'privacy-policy',
            element: <LoadComponent component={PrivacyPolicy} />,
        },
        {
            path: 'terms-and-conditions',
            element: <LoadComponent component={TermsAndConditions} />,
        },
        {
            path: 'cookie-policy',
            element: <LoadComponent component={CookiePolicy} />,
        },
        {
            path: '/protected',
            element: <PrivateRoute roles={'Admin'} />,
            children: [
                // Add protected routes here if needed in the future
            ],
        },
        // Add the 404 route at the end
        {
            path: '*',
            element: <LoadComponent component={NotFound} />,
        },
    ]);
};

export default AllRoutes;
